@use '@angular/material' as mat;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
// Change orange contrast settings so we got white text over
// orange background instead of black/orange.
$app-orange: map-merge(
  mat.$orange-palette,
  (
    contrast: (
      50: rgba(black, 0.87),
      100: rgba(black, 0.87),
      200: rgba(black, 0.87),
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: rgba(black, 0.87),
      A200: white,
      A400: white,
      A700: white,
    ),
  )
);
$custom-typography: mat.define-typography-config(
  $font-family: "Roboto, monospace",
  $headline-5: mat.define-typography-level(32px, 48px, 700),
  $body-2: mat.define-typography-level(14px, 24px, 400),
);
@include mat.typography-hierarchy($custom-typography);

// Override typography for a specific Angular Material components.
@include mat.checkbox-typography($custom-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($custom-typography);
$app-primary: mat.define-palette($app-orange);
$app-accent: mat.define-palette($app-orange, A400, A100, A700);

// The warn palette is optional (defaults to red).
//$app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
//$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);
$app-theme: mat.define-light-theme((
  color:(
    primary: $app-primary,
    accent: $app-accent
  ),
  typography: $custom-typography
));

$app-dark-primary: mat.define-palette($app-orange);
$app-dark-accent: mat.define-palette($app-orange);
$app-dark-theme: mat.define-dark-theme($app-dark-primary, $app-dark-accent);

.fixed-toolbar-container {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
  @include mat.elevation(3);
}

// custom changes

mat-sidenav {
  background-color: #3c4858 !important;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
}

mat-tree-node {
  background-color: #3c4858 !important;
  color: white !important;
  text-transform: capitalize !important;
  font-size: 13px !important;
  padding: 10px 15px !important;
  cursor: pointer;
  text-decoration: none;
  a {
    color: white !important;
    cursor: pointer;
    color: #3c4858;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    width: 100%;
    padding-left: 10px;
    mat-icon {
      margin-right: 10px;
    }

    &:hover {
      background-color: #48cfad;
      color: black;
      box-shadow: none;
      border-radius: 5px;
    }
  }
}

mat-toolbar {
  background-color: #3c4858 !important;
  padding: 0 5px;
  color: white !important;
}

.header-inputs {
  padding: 15px;
  border-radius: 5px;
  background: linear-gradient(60deg, #4c5c70, #3c4858);
  color: white;
  min-width: 30%;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-empty.mat-form-field-label,
  .mat-form-field-infix.mat-mdc-select{
    color: white;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  ::ng-deep .mat-focused .mat-form-field-label {
    /*change color of label*/
    color: white !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value {
    color: white;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    /*change color of underline*/
    background-color: white !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-ripple {
    /*change color of underline when focused*/
    background-color: white !important;
  }
}
