/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "./css/mat-theme.scss";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($app-theme);
$theme: map-merge((), $app-theme);
$background: map-get($theme, background);
$foreground: map-get($theme, divider);
$primary: map-get($theme, primary);

@include mat.all-component-themes($app-theme);

.app-dark-theme {
  @include mat.all-legacy-component-themes($app-dark-theme);
}
mat-sidenav-container {
  height: 100vh;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.timepicker {
  .timepicker__header {
    background-color: map-get($primary, default) !important;
  }
  .clock-face__clock-hand {
    background-color: map-get($primary, default) !important;

    &::after {
      background-color: map-get($primary, default) !important;
    }

    &::before {
      border-color: map-get($primary, default) !important;
    }
  }
  .clock-face__number {
    > span.active {
      background-color: map-get($primary, default) !important;
    }
  }

  button:not(.timepicker-dial__item) {
    color: map-get($primary, default) !important;
  }
}

.mat-mdc-card {
  padding-inline: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    display: inline;
}

button[mat-raised-button]{
  color: white !important;
  &.color_black{
    color: black !important;
  }
}
/* .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}
 */